@import '../../themes/variables';
.content-pergunta {
  .content {
    flex-direction: row-reverse;
    margin: 10px;
    max-height: 500px;
  }

  .pergunta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 05px;
    border: 1px solid $gray;
    border-radius: 20px;
    width: 80%;
    position: relative;
    height: 100%;
    background-color: $white;
    overflow-x: hidden;

    .nome {
      color: $gray;
      margin-left: 10px;
      strong {
        font-weight: 800;
        margin-left: 3px;
      }
    }

    .texto {
      color: $gray;
      margin-left: 10px;
      position: relative;
      display: flex;
      flex-direction: column-reverse;

      p {
        overflow: hidden;
      }

      img {
        max-width: 100%;
        max-height: 200px;
        justify-content: center;
      }
    }
    .data {
      margin: 10px;
      font-size: 10px;
    }
  }
}
