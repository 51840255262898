@font-face {
  font-family: 'Mohr';
  src: url('../assets/fonts/Mohr-Medium.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Mohr';
  src: url('../assets/fonts/Mohr-Bold.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Mohr';
  src: url('../assets/fonts/Mohr-SemiBold.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Mohr-Light';
  src: url('../assets/fonts/Mohr-Light.otf');
  font-style: normal;
  font-weight: 200;
}
