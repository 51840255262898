.filter-query {
  .filter-data {
    max-width: 180px;
  }

  form {
    .query-button {
      margin-right: 10px;
      border-radius: 30px;
      min-width: 150px;
      padding-top: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
}
