.extrato-downloads {
  margin: 20px;
  font-size: 20px;

  table{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .table-striped tbody tr:nth-of-type(even){
    background-color: white;
  }
  .btn-extrato {
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  th {
    font-size: 24px;
  }

}
