@import '../../themes/variables';
.duvidasFrequentes {
  @media (min-width: 768px) {
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 100%;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
    background: $primary 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    height: 50px;
    color: $white;
    font-size: 25px;
    padding-left: 30px;
  }

  .info {
    background-color: $white;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 0px 0px 20px 20px;
    padding: 20px;

    .rowDoubts {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      cursor: pointer;

      label {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .icone {
          display: flex;
          flex-direction: row;
          justify-self: flex-end;
        }
      }

      #respostas {
        span {
          flex-direction: row;
          justify-self: flex-start;

          hr {
            max-width: 600px;
          }
        }
      }
    }
  }
}
