@import '../../themes/variables';

.content-form-login {
  display: flex;
  justify-content: flex-end;
  flex: 1;

  svg {
    color: $white-half-opacity;
    margin-top: 13px;
    margin-right: 10px;

    &:hover {
      color: $white;
    }

    @media screen and (min-width: 321px) {
      margin-top: 17px;
    }

    @media screen and (min-width: 993px) {
      display: none;
    }
  }

  .turn-off-the-light {
    background-color: $black;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.2s linear;
    z-index: 99;
    @media screen and (min-width: 993px) {
      display: none;
    }
  }

  .form-login {
    z-index: 100;
    background-color: $white;
    height: 100vh;
    position: absolute;
    width: 70%;
    top: 0;
    right: -70%;
    padding: 4px 8px;
    transition: all 0.2s linear;
    visibility: hidden;
    transition: visibility 0s, right 0.2s linear;

    > div {
      margin-bottom: 15px;
      text-align: center;
      color: $gray;
      &:nth-of-type(1) {
        text-align: left;
      }
      svg {
        color: $gray;
        @media screen and (min-width: 993px) {
          display: none;
        }
      }
    }

    input {
      margin: 7px auto;
    }

    button {
      border-radius: 30px;
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
    }

    a {
      color: $white;
    }

    a:hover {
      text-decoration: none;
      color: $gray-light;
    }

    @media screen and (min-width: 993px) {
      background-color: inherit;
      height: inherit;
      position: inherit;
      width: inherit;
      top: inherit;
      right: inherit;
      padding: inherit;
      visibility: inherit;
      transition: visibility 0s, right 0.2s linear;

      display: flex !important;
      margin-top: -20px;
      padding-right: 100px;

      div {
        margin: 0 5px;
        color: $white-half-opacity;
        font-weight: 100;
      }

      input {
        margin: 0;
      }

      button {
        border-radius: 30px;
        width: 120px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  #check {
    display: none;
    &:checked {
      ~ form .form-login {
        visibility: visible;
        right: 0;
      }
      ~ label .turn-off-the-light {
        visibility: visible;
        opacity: 0.6;
      }
    }

    &:not(:checked) {
      ~ form .form-login {
        display:none;
      }
    }
  }
}
