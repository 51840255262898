// Fonts
//$font-family: 'Mohr';
$font-family: 'Mohr-Light';


// Colors
$primary: #00A000;
$primary-hover: #021f59;
$primary-selecionado: #ff9900;
$primary-light: #7daee3;
$danger: #ff0000;
$sucess: #00A000;
$sucess-light: lightgreen;
$secondary: #707070;
$theme-color-primary: #021f59;
$theme-color-secondary: #021f59;
$turn-off-the-light: rgba(0, 0, 0, 0.5);
$turn-off-the-lighter: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;
$black2: #00000029;
$white-half-opacity: rgba(255, 255, 255, 0.8);
$gray: #707070;
$gray-light: #e6e6e6;
$gray-lighter: #fafafa;

$amountOfLight: 10%;
$primary-dark: darken($primary, $amountOfLight);

