@import '../../themes/variables';
.filter-artes {
  .titulo {
    background-color: $primary;
    max-width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: $white;
    font-size: 25px;
    padding: 10px;
    box-shadow: 0px 3px 6px $black2;
  }
  .body {
    background-color: $gray-light;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-height: 650px;
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    box-shadow: 0px 3px 6px #B0B0B0;
    color: $gray;

    .default {
      display: flex;
      justify-content: center;
      border-top-color: #C0C0C0;
      position: relative;
      min-width: 95%;
    }

    .input {
      justify-content: center;
      font-size: 21px;
      padding: 15px 15px;
    }
    .input input {
      justify-content: center;
      width: 95%;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: $white 0% 0% no-repeat padding-box;
      border: 1px solid $primary;
      border-color: $primary-light;
      border-radius: 5px;

      outline-color: $primary;
      opacity: 1;
    }

    .input input:focus {
      border-color: $primary-light;
      box-shadow: 0 0 0 0.2rem $primary;
      outline: 0;
    }

    .filters {
      padding: 0px 15px 0px 15px;

      label {
        border: none;
        padding: 0;
        background-color: transparent;
        color: $gray;
        font-size: 21px;
        letter-spacing: 0px;
        opacity: 1;
        margin: 0;
        text-align: left;
      }

      #itensMaterial,
      #itensCampanha {
        flex-direction: column;
        position: relative;

        .float-right {
          font-size: 1rem;
          margin-top: 10px;
        }
        input {
          flex-direction: row;
          display: inline;
          position: relative;
          flex-direction: row;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 15px 0px 15px;

      .query-button {
        margin-bottom: 10px;
        border-radius: 30px;
        min-width: 100%;
        width: auto;
        box-shadow: 0px 3px 6px $black2;
        position: relative;
      }
    }
  }

  .btn.btn-outline-primary{
    background-color: white;
  }

  .btn.btn-outline-primary:hover{
      color: $white;
      background-color: $primary-hover;
  }
}
@media only screen and (max-width: 620px) {
  .filter-artes {
    .body {
      min-height: 500px;
    }
  }
}
