@import '../../../themes/variables';

.wrapper-chamado-enviado {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
    height: 460px;

    .enviado {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-weight: 800;
        font-size: 25px;
        margin: 20px;
      }

      small {
        font-size: 20px;
        margin: 20px;
      }

      .btnVoltar {
        padding-top: 8px;
        height: 42px;
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        width: 150px;
        margin: 20px;
        .icon {
          margin-right: 10px;
        }
      }
      .btnVoltar:hover {
        background: $primary-hover 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
      }
    }
  }
}
