@import '../../../themes/variables';
.loader-gif {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white-half-opacity ;
  width: 100%;
  height: 100%;
  z-index: 100000;

  div {
    position: fixed;
    top: 45%;
    left: 50%;
  }
}
