@import '../../../themes/variables.scss';

.table-historico-pedidos {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px;

  table.table {
    border-top: none;
    th {
      font-size: 20px;
      font-weight: 900;
      border-top: none;
    }
  }

  .table tbody{
    box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 5px -1px, rgba(0, 0, 0, 0.3) 1px 1px 3px -1px;
  }
  .table-striped tbody tr:nth-of-type(even){
    background-color: white;
  }

  td {
    padding: 0.5rem;
  }

  .table-button {
    border-radius: 30px;
    min-width: 140px;
    font-size: 18px;
    height: 36px;
    padding-top: 6px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .cancelado {
    color: $danger;
  }
}
