@import '../../../themes/variables';
.wrapper-banco-imagens {
  .content {
    display: flex;
    margin-top: 30px;
    color: $gray;

    .column1 {
      display: flex;
      justify-content: center;
      max-width: none;
    }
    .column2 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .order {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap-reverse;

        .todas {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 8px;

          Button {
            border-radius: 30px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          }
        }
        .select {
          margin-bottom: 8px;
        }

        .download {
          display: flex;
          flex-direction: row;

          Button {
            box-shadow: 0px 3px 6px $black2;
            border-radius: 30px;
            min-width: 200px;
            padding-right: 12px;

            .total {
              background-color: $white;
              color: $gray;
              margin-left: 5px;
              border-radius: 25px;
              width: 30px;
              text-align: center;
            }
          }
          .icon {
            margin: 5px;
            justify-content: center;
            vertical-align: -1rem;
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      .download {
        justify-content: center;
      }
    }
  }
  @media only screen and (max-width: 620px) {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .column2 {
        .card-deck {
          justify-content: center;
          flex-flow: row wrap;
        }
      }

      .download {
        display: flex;
        justify-content: center;
      }
    }
  }
  .pagination{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}
