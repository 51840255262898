@import '../../themes/variables';
.card-deck {
  display: flex;
  position: static !important;
  flex-direction: row;
  justify-content: flex-start;
  .card {
    border-radius: 20px !important;
    max-width: 255px;
    min-width: 240px;
    border: 0px solid $black !important;
    background-color: $gray-lighter !important;
    padding-bottom: 15px;
    margin: 25px 15px 15px 15px !important;
    box-shadow: 0px 3px 6px $black2;
  }

  .card:hover {
    box-shadow: 0 0 0 0.2rem $primary;
    .card-title {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  .card .card-title {
    background-color: $primary;
    min-height: 85px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: static;
    padding: 10px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }
  .card .card-title span {
    color: $white;
    font-size: 20px;
    position: static;
    margin: 0;
    justify-content: center;
    word-wrap: break-word;
  }
  .card .card-body {
    padding-bottom: 0;
    padding-top: 0;
  }
  .card .card-body .row a {
    font-size: 12px;
  }
  .card .card-text {
    background-color: $white;
    height: 60px;
    position: static;
    padding-top: 11px;
    font-size: 20px;
  }

  .card .img-card {
    span {
      align-self: center;
      padding-right: 10px;
      padding-top: 5px;
      text-align: right;
      width: 100%;
      position: absolute;
    }
    max-width: 100%;
    height: 170px;
  }
  .card .img-card .card-img-top {
    position: static;
    max-width: 100%;
    height: 170px;
    object-fit: contain;
    padding: 10px;
  }
  .card .card-body .btn-primary {
    padding: 5px 10px 5px 10px;
    max-width: 100px;
    min-width: 110px;
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 30px;
    opacity: 1;
  }
  .card .card-body .btn-primary:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  .card .card-body .btn-primary span {
    margin-left: 3px;
  }

  .card .card-body .col-md-auto {
    padding: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .card .card-body .col-md-auto {
    display: none;
  }
  .card .card-body .row {
    display: flex;
    flex-direction: column;
  }
  .card .card-body .btn-primary {
    max-width: 100% !important;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 992px) {
  .card .card-text {
    padding-top: 0 !important;
  }

  .card .card-body .col-md-auto {
    display: none;
  }

  .card .card-body .btn-primary {
    width: 100%;
    margin-bottom: 5px;
  }
}
