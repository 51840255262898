@import '../../themes/variables';

.sub-header {
  width: 100%;
  display: flex;

  box-shadow: 3px 3px 4px $turn-off-the-lighter;
  height: 60px;

  align-items: center;
  padding: 0 30px;

  text-transform: uppercase;
  font-size: 1.3rem;

  color: $gray;
  background-color: $white;
}
