@import '../../themes/variables';
@import '../../themes/mixins.scss';

.footer-fixed {
  @include gradient(right);
  height: 60px;
  width: 100%;
  color: $white;
  padding: 10px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;

  p {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 321px) {
    height: 70px;
  }
}
