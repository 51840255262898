@import '../../../themes/variables.scss';
@import '../../../themes/mixins.scss';

.header-app {
  width: 100%;
  height: 60px;
  @include gradient(right);
  display: flex;

  @media screen and (min-width: 321px) {
    height: 70px;
  }

  .brand-app {
    flex: 4;
    display: flex;
    justify-content: center;

    img {
      max-width: 170px;
      margin-top: 13px;
    }

    @media screen and (min-width: 993px) {
      flex: 3;
      justify-content: inherit;

      img {
        max-width: 190px;
        margin-top: 13px;
      }
    }
  }
}
