@import '../../../../themes/variables.scss';

.wrapper-content-pedido-resumo {
  .content-pedido-resumo {
    position: relative;
    min-height: calc(100vh - 120px);
padding: 0 20px 20px 20px;
background: white;
    max-width: 1280px;
    margin: auto;

    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }

    .accordion {
      width: 100%;
    }

    .resumo {
      border: 1px solid $black;
      padding: 0;
      border-radius: 12px;

      .resumo-header {
        border-radius: 12px 12px 0 0;

        .card-header {
          font-size: 22px;
          background: none;
          border: none;
        }
      }

      .resumo-body {
        padding: 30px 20px;

        .table th,
        .table td {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .table th {
          width: 1%;
          white-space: nowrap;
          padding-right: 30px;
        }

        .resumo-informacoes {
          margin-bottom: 30px;

          img {
            max-height: 200px;
          }
        }

        .resumo-grid-produtos {
          font-size: 13px;

          label {
            display: block;
            margin-bottom: 0;
            font-weight: 500;
          }

          .row {
            margin-bottom: 10px;
          }

          .row-striped {
            padding-top: 10px;
            margin: 0;
          }

          .row-striped:nth-of-type(odd) {
            background-color: $gray-light;
          }

          .produto-preco {
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
