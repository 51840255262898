@import '../../themes/variables';
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
  background: $primary 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  height: 50px;
  color: $white;
  font-size: 25px;
  padding-left: 30px;
}

.info {
  background-color: $white;
  font-weight: 800;
  padding-top: 20px;
  padding-bottom: 20px;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $black2;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;

  .botoes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .query-button-called {
    background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 30px;
    opacity: 1;
    max-height: 100px;
    height: 45px;
    font-size: large;
    margin-bottom: 15px;
  }
  .query-button-called:hover {
    background: var(--unnamed-color-2d2f93) 0% 0% no-repeat padding-box;
    background: $primary-hover 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
  }
  span {
    font-weight: 300;
  }
}
