@import '../../../themes/variables';

.wrapper-chamado {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;

    .motivo {
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
      margin-bottom: 70px;
      padding: 20px;

      label {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 800;
      }
      .select {
        margin: 0;
        max-width: 400px;
      }
    }

    .mensagem {
      textarea {
        height: 162px;
        background: $white 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        outline: 0;
      }
      textarea:focus {
        background-color: $white;
        border-color: $primary-light;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $primary;
      }
    }

    .enviar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .botao {
        height: 42px;
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        max-width: 200px;
        min-width: 100px;
        width: 150px;
        padding-top: 8px;

        .icon {
          margin-right: 10px;
        }
      }
      .botao:hover {
        background: $primary-hover 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
      }

      .input {
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        border: 1px solid var(--unnamed-color-286db8);
        background: $primary 0% 0% no-repeat padding-box;
        border: 1px solid  $primary;
        border-radius: 5px;
        color: $white;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        margin: 0;
        height: 42px;
      }
      input[type='file'] {
        display: none;
      }
      .input:hover{
        background-color: $primary-hover !important;
      }
    }

    .voltar {
      margin: 30px 0 0px 0;

      .btnVoltar {
        flex-direction: row;
        align-self: flex-start;
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background:  $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        padding-top: 8px;
        .icon {
          margin-right: 10px;
        }
      }
      Button:hover,
      .btnVoltar:hover {
        background: $primary-hover 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
      }
    }
    @media only screen and (max-width: 577px) {
      .motivo {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-content: center;
      }

      .enviar {
        display: block;
        text-align: center;
        Button {
          max-width: 150px;
        }

        label {
          text-align: center;
        }
      }

      .voltar {
        margin: 0px 0 0px 0;
        text-align: center;
      }
    }
  }
}
