@import '../../themes/variables';
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
  background: $primary 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  height: 50px;
  color: $white;
  font-size: 25px;
  padding-left: 30px;
}

.info {
  background-color: $white;
  font-weight: 800;
  padding-top: 20px;
  padding-bottom: 20px;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $black2;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;

  .text {
    border: 1px solid var(--unnamed-color-286db8);
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $primary;
    border-radius: 5px;
  }
  .formPassword .msg {
    text-align: center;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .query-button {
    background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 30px;
    opacity: 1;
    width: 150px;
    height: 45px;
    font-size: large;
  }
  .query-button:hover {
    background: var(--unnamed-color-2d2f93) 0% 0% no-repeat padding-box;
    background: $primary-hover 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
  }
}
