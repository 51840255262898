@import '../../../themes/variables';

.input-file-custom {
  .input-file {
    display: none !important;
  }

  label {
    background-color: $primary;
    border-radius: 5px;
    color: $white !important;
    padding: 6px 20px;
    cursor: pointer;
    font-weight: normal;
  }

  label:hover {
    background-color: $primary-hover;
  }
}
