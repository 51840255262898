@import '../../../../themes/variables.scss';

.wrapper-content-pedido-materiais {
  .content-pedido-materiais {
    position: relative;
    min-height: calc(100vh - 120px);
padding: 0 20px 20px 20px;
background: white;
    max-width: 1024px;
    margin: auto;

    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }
  }
}
.div-cards{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.materiais-content-campanha{
  .body-card-material{
    box-shadow: none!important;
    border: 2px solid transparent;
    border-radius: 50%;
    &:hover {
      border: 2px solid #011f59;
      border-radius: 50%;
    }
    .lista-campanha-materiais {
      box-shadow: none!important;
    }
  }
}
