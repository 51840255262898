@import '../../../themes/variables';

.wrapper-banco-Artes {
  .content {
    display: flex;
    margin-top: 30px;

    .column1-artes {
      display: flex;
      justify-content: center;
      max-width: none;
      padding-right: 0;
    }
    .column2-artes {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .order {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap-reverse;
        margin-right: 20px;

        Button {
          border-radius: 30px;
          box-shadow: 0px 3px 6px $black2;
        }

        .select {
          margin-bottom: 8px;
        }
      }

      .cards {
        .cards-align {
          padding-right: 0;
        }
      }
    }
  }

  .pagination{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}

@media only screen and (max-width: 654px) {
  .wrapper-banco-Artes {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .column2-artes {
        .order {
          margin-right: 0;
        }
        .card-deck-artes {
          justify-content: center;
          flex-flow: row wrap;
        }
      }
    }
  }
}
