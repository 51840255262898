@import '../../themes/variables';

.content-home{

  overflow: auto;
  .wrapper-home {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 240px);

    .icon-home {
      height: 52px;
      width: auto;
      margin-right: 10px;
    }


    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 240px);
    }

    @media screen and (min-width: 993px) {
      flex-direction: row;
    }

    .warnings {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 27px 10px;

      @media screen and (min-width: 993px) {
        min-width: 500px;
      }
    }
    .actions {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      flex-direction: column;

      @media screen and (min-width: 993px) {
        padding: 10px 10px;
      }

      > div {
        width: 100%;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        color: $white;

        @media screen and (min-width: 993px) {
          flex-direction: row;
        }

        a {
          text-decoration: none;
          color: $white;
          flex: 1;
          margin: 10px 0;

          @media screen and (min-width: 993px) {
            margin: 20px 10px;
          }

          > div {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            background-color: $primary;
            cursor: pointer;

            flex: 1;
            border-radius: 60px;
            min-height: 115px;
            margin: 10px 0 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
              text-transform: uppercase;
              display: flex;
              font-weight: 500;
              font-size: 1.3rem;

              &:first-of-type {
                background-color: transparent;
                flex: 1;
                justify-content: flex-end;
                margin-right: 10px;
                img {
                  margin: 0 10px 0 20px;
                  max-height: 55px;
                  max-width: 68px;
                  width: auto;
                }
              }
              &:last-of-type {
                flex: 4;
                margin-left: 10px;
                background-color: transparent;
              }
            }

            &:first-of-type {
              margin-top: 0;
            }
            &:last-of-type {
              margin-bottom: 0;
            }

            @media screen and (min-width: 993px) {
              margin: 0 20px;

              &:first-of-type {
                margin-left: 0;
              }
              &:last-of-type {
                margin-right: 0;
              }
            }

            &:hover {
              background-color: $primary-hover;
            }
          }
        }
      }
      p {
        text-align: center;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 500;
        color: $primary;
      }
      p.colors-teste-teste {
        background-color: $primary;
      }
    }
  }
  .texto-atendimento{
    text-align: center;
    min-height: 100px;
    display: flex;
    align-items: center;

    p {
      font-family: "Mohr";
      text-align: center;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $primary;
      padding: 0 35px 0 35px;
      margin: 0;
    }
  }
  .btn-divider {
    background-color: $white;
    width: 2px;
    height: 70px;
  }
}
