@import '../../../../../../themes/variables';

.apexcharts-tooltip {
  z-index: 9999;
  .arrow_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 10px;
    font-family: $font-family;
    span {
      font-size: 0.9rem;
      color: $gray;
      font-weight: lighter;
    }
    #data {
      color: #0000ff;
    }
  }
}

.grafico {
  border-radius: 20px;
  border: 0px solid $black !important;
  background-color: $gray-lighter !important;
  box-shadow: 0px 3px 6px $black2;
  transition: 0.1s;

  flex: 1;

  span {
    font-size: 1.3rem;
    font-weight: bolder;
    color: #000000;
  }

  // .arrow_box {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   gap: 8px;
  //   padding: 10px;
  //   font-family: $font-family;
  //   span {
  //     font-size: 0.9rem;
  //     color: $gray;
  //     font-weight: lighter;
  //   }
  //   #data {
  //     color: #0000ff;
  //   }
  // }
}

.grafico-titulo {
  background-color: $theme-color-primary;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 40px;
  padding: 10px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  font-style: $font-family;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input{
    width: 50%;
    height: 25px;
    border-radius: 5px;
    margin-left: 10px;
  }

  span {
    color: $white;
    font-size: 1rem;
    position: static;
    margin: 0;
    justify-content: center;
    word-wrap: break-word;
  }
}
.grafico:hover {
  box-shadow: 0 0 0 0.2rem $theme-color-primary;
  .grafico-titulo {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.grafico-sem-dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: $font-family;
  span {
    font-size: 1.3rem;
    color: $gray;
    font-weight: lighter;
  }
}

.mapa {
  // padding: 10px;
  max-width: fit-content;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-family: $font-family;
    font-size: 1.1rem;
  }

  .titulo {
    font-weight: bold;
    color: #000000;
  }

  .mapa-container {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;
    background-color: #fff;
    width: 450px;
    height: auto;
    // stroke: #000000; //cor das labels

    .zoom-buttons {
      position: absolute;
      top: 10px;
      left: 10px;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    .tooltip-mapa {
      font-family: $font-family;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 5px;
      border-radius: 3px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transform: translate(5%, -100%);
      z-index: 9999;
    }
  }

  .legenda-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    // background-color: #3cff00;

    span {
      font-size: 12px;
      color: #000000;
      font-weight: bold;
    }
    .legenda {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      // background-color: #808089;
      width: 100%;
      .escala {
        width: 100%;
        height: 10px;
        border-style: solid;
        border-width: 1px;
        border-color: #aca899;
      }
      .um {
        background-color: #89afc9;
      }
      .dois {
        background-color: #4990c0;
      }
      .tres {
        background-color: #7fce9a;
      }
      .quatro {
        background-color: #30ca66;
      }
      .gradiente-custo {
        margin-right: 10px;
        margin-left: 10px;
        background: linear-gradient(
          90deg,
          #30ca60 0%,
          #d2d2d1 50%,
          #324977 100%
        );
      }
      .gradiente-participacao {
        margin-right: 10px;
        margin-left: 10px;
        background: linear-gradient(
          90deg,
          #d4d4d4 0%,
          #c9ffdc 50%,
          #30ca67 100%
        );
      }
    }
  }
}

.scrollable {
  min-height: 250px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5555553e;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6363636e;
  }
}
