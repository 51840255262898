@import '../../../themes/variables.scss';

.wrapper-content-alterar-solicitacao {
  position: relative;
  min-height: calc(100vh - 120px);
  max-width: 1280px;
  margin: auto;

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 140px);
  }

  .solicitacao-panel-body{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  button{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  }

  .btn.btn-link{
    padding-left: 10px;
    padding-right: 10px;
  }
}
